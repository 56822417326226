import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query'
import { useRouter } from 'next/router'
import {
  getProjectsService,
  getProjectService,
  getProjectsTags,
  getInfiniteProjectsService,
} from 'services/projects'
import useSession from './session'

export function useInitalProjects() {
  const token = ''
  const filter = null
  const search = null
  const size = null
  const page = 1
  return useQuery(
    ['GET_PROJECTS', token, filter, search, page, size],
    () => getProjectsService({
      filter,
      token,
      page,
    }),
  )
}

export function useInfiniteProjects(params) {
  const queryClient = useQueryClient()
  const { query } = useRouter()
  const search = query.search || params?.search || null
  const level = query.level || params?.level || null
  const property = query.property || params?.property || null
  const type = query.type || params?.type || null
  const filter = query.filter || params?.filter || null
  const { token } = useSession()
  const projectsQuery = useInfiniteQuery(
    ['GET_INFINITE_PROJECTS', token, level, property, type, filter, search],
    ({ pageParam }) => getInfiniteProjectsService({
      level, property, type, page: pageParam, filter, search, token,
    }),
    {
      getNextPageParam: ({ next }) =>{
        const nextParam = next?.slice(next?.indexOf('page=') + 5)
        return nextParam
      },
      getPreviousPageParam: ({ previous }) =>{
        const previousParam = previous?.slice(previous?.indexOf('page=') + 5)
        return previousParam
      },
      initialData: () => {
        const queryCacheData = queryClient.getQueryData(['GET_PROJECTS', '', null, null, 1, null, level])
        if (queryCacheData) {
          return {
            pageParams: [undefined, '2'],
            pages: [queryCacheData],
          }
        }
      },
    },
  )
  return { ...projectsQuery }
}

export function useProjects(params) {
  const queryClient = useQueryClient()
  const { query } = useRouter()
  const size = query.size || params.size || null
  const search = query.search || params.search || null
  const level = query.level || params.level || null
  const property = query.property || params.property || null
  const type = query.type || params.type || null
  const filter = query.filter || params.filter || null
  const page = parseInt(query.page, 10) || 1
  const { token } = useSession()
  const projectsQuery = useQuery(
    ['GET_PROJECTS', token, level, search, page, size, property, type, filter],
    () => getProjectsService({
      level, type, token, filter, search, page, size,
    }),
    {
      initialData: () => queryClient.getQueryData(['GET_PROJECTS', 'MX', '', null, null, 1, null, level]),
      // keepPreviousData: true,
    },
  )
  return { ...projectsQuery, page }
}

export function useProjectsTags() {
  return useQuery(
    'GET_PROJECTS_TAGS',
    () => getProjectsTags(),
  )
}

export async function prefetchProjects(queryClient, props) {
  const token = ''
  const filter = null
  const search = null
  const page = 1
  const size = null
  const level = props?.level || null
  await queryClient.prefetchQuery(
    ['GET_PROJECTS', token, filter, search, page, size, level],
    () => getProjectsService({
      filter,
      token,
    }),
  )
  return queryClient
}

export function useProjectKey(projectSlug) {
  const { project: projectFromQuery } = useRouter().query
  const project = projectFromQuery || projectSlug
  const { token } = useSession()
  return ['GET_PROJECT', project, token]
}

export function useProject(args) {
  const queryClient = useQueryClient()
  const PROJECT_KEY = useProjectKey(args?.projectSlug)
  const [key, project, token] = PROJECT_KEY
  const query = useQuery(
    PROJECT_KEY,
    () => getProjectService({
      project,
      token,
    }),
    {
      enabled: !!project,
      initialData: () => {
        return queryClient.getQueryData(['GET_PROJECT', project, ''])
      },
      placeholderData: {
        is_free: false,
      },
    },
  )
  return { ...query, token }
}

export async function prefetchProject(queryClient, project, serveToken) {
  const token = serveToken || ''
  await queryClient.prefetchQuery(
    ['GET_PROJECT', project, token],
    () => getProjectService({
      project,
      token,
    }),
  )
  return queryClient
}

import handlePromise from 'utils/handle-promise'

export const hostname = process.env.API_HOST || 'https://api.learning.rest'

function createUrlParams({ level, search, property, type, page, filter }) {
  const filterQuery = filter ? `&filter=${filter}` : ''
  const searchQuery = search ? `&search=${search}` : ''
  const levelQuery = level ? `&level=${level}` : ''
  const typeQuery = type ? `&type=${type}` : ''
  const properyQuery = property ? `&${property}=true` : ''
  const pageQuery = page ? `&page=${page}` : ''
  const querys = `${pageQuery}${properyQuery}${levelQuery}${typeQuery}${searchQuery}${filterQuery}`
  return querys
}

export async function getProjectsInProgressService(params) {
  const querys = createUrlParams(params)
  const url = `/api/v1/projects/progress/?country_code=${params.countryCode}${querys}`
  const data = await handlePromise(url, { method: 'GET', headers: { Authorization: params.token } })
  return data
}

export async function getPendingProjectsService(params) {
  const querys = createUrlParams(params)
  const url = `/api/v1/projects/path/?country_code=${params.countryCode}${querys}`
  const data = await handlePromise(url, { method: 'GET', headers: { Authorization: params.token } })
  return data
}

export async function getInfiniteProjectsService(params) {
  const querys = createUrlParams(params)
  const url = `/api/v1/projects/?country_code=${params.countryCode}${querys}`
  const data = await handlePromise(url, { method: 'GET', headers: { Authorization: params.token } })
  return data
}

export async function getProjectsService({
  countryCode, token, level, type, device, page, size, search, filter,
}) {
  const sizeQuery = size ? `&page_size=${size}` : ''
  const pageQuery = page ? `&page=${page}` : ''
  const levelQuery = level ? `&level=${level}` : ''
  const typeQuery = type ? `&type=${type}` : ''
  const searchQuery = search ? `&search=${search}` : ''
  const filterQuery = filter ? `&filter=${filter}` : ''
  const url = `/api/v1/projects/?country_code=${countryCode}${sizeQuery}${pageQuery}${filterQuery}${searchQuery}${levelQuery}${typeQuery}`
  const data = await handlePromise(url, {
    method: 'GET',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  }, true)
  return data
}

export async function getProjectService({
  project, token, countryCode, email,
}) {
  const emailQuery = email ? `&email=${email}` : ''
  const url = `/api/v1/projects/${project}/?country_code=${countryCode}${emailQuery}`
  const data = await handlePromise(url, {
    method: 'GET',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
  return data
}

export async function startProjectService({ project, token }) {
  const url = `/api/v1/projects/${project}/enrrollment/`
  const data = await handlePromise(url, {
    method: 'POST',
    headers: {
      Authorization: token,
      'Content-Type': 'aplication/json',
    },
  })
  return data
}

export async function getProjectFileService({ slug, token, setProgress }) {
  const url = `${hostname}/api/v1/projects/${slug}/files/`
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  })
  const reader = res.body.getReader()

  // Step 2: get total length
  const contentLength = res.headers.get('Content-Length')

  // Step 3: read the data
  let receivedLength = 0 // received that many bytes at the moment
  let downloaded = 0
  let newdownloaded = 0
  const chunks = [] // array of received binary chunks (comprises the body)
  while (true) {
    const { done, value } = await reader.read()

    if (done) {
      setProgress(100)
      break
    }

    chunks.push(value)
    receivedLength += value.length
    newdownloaded = (receivedLength * 100) / contentLength
    if (newdownloaded > downloaded + 2) {
      setProgress(newdownloaded)
      downloaded = newdownloaded
    }
  }
  const blob = new Blob(chunks)
  const elm = document.createElement('a')
  elm.href = window.URL.createObjectURL(blob)
  elm.setAttribute('download', `${slug}.zip`)
  elm.click()
  return true
}

export async function updateProjectStatusService({ project, token, payload }) {
  const url = `/api/v1/projects/${project}/update/`
  const data = await handlePromise(url, {
    method: 'PUT',
    headers: {
      Authorization: token,
    },
    body: payload,
  })
  return data
}

export async function getProjectsTags() {
  const url = '/api/v1/projects/tags'
  const data = await handlePromise(url)
  return data
}

export async function studentProjectsService({ size, page, search, level, type, property }) {
  const pageSize = size || 25
  const actualPage = page || 1
  const searchQuery = search ? `&search=${search}` : ''
  const levelQuery = level ? `&project__level=${level}` : ''
  const typeQuery = type ? `&project__type=${type}` : ''
  const properyQuery = property ? `&${property}=true` : ''
  const querys = `${properyQuery}${levelQuery}${typeQuery}${searchQuery}`
  const url = `/api/v1/student-projects/?page_size=${pageSize}&page=${actualPage}${querys}`
  const data = await handlePromise(url, { method: 'GET' })
  return data
}

export async function studentProjectService({ uuid }) {
  const url = `/api/v1/student-projects/${uuid}/`
  const data = await handlePromise(url, { method: 'GET' }, true)
  return data
}

export async function studentProjectsPodiumService({ filter }) {
  const filterQuery = filter ? `?filter=${filter}` : ''
  const url = `/api/v1/student-projects/podium/${filterQuery}`
  const data = await handlePromise(url, { method: 'GET' })
  return data
}
